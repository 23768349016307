import { bindable, observable } from 'aurelia-framework';
import { MemberCarriers } from 'services/member-carriers';

export class DownlineCarrierDebt {
    static inject = [MemberCarriers];
    _carriers;

    @bindable({ changeHandler: '_load' }) memberId;
    @observable member;

    _memberCarriers;

    constructor(memberCarriers) {
        this._memberCarriers = memberCarriers;
    }

    attached() {
        this._isAttached = true;
        this._load();
    }

    memberChanged() {
        if (!this.member) {
            this.forMemberId = undefined;
        } else {
            this.forMemberId = this.member.id;
        }
        this._load();
    }

    async _load() {
        if (!this._isAttached) return;
        if (!this.memberId && !this.forMemberId) return;
        this.loading = true;
        try {
            this.downline = [];
            const forMemberId = this.forMemberId ?? this.memberId;
            this.downline = await this._memberCarriers.downlineDebt(forMemberId);
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
