import { bindable } from 'aurelia-framework';
import { Contests } from 'services/contests';
import { Security } from 'common/security';
import moment from 'moment';

export class ContestStandings {
    static inject = [Contests, Security];
    @bindable code = null;
    @bindable staticHeight = false;
    _contests;
    security;

    standings;
    standingsFilters = [
        { value: '', custom: this.leaderboardTypeFilter },
    ];

    constructor(contests, security) {
        this._contests = contests;
        this.security = security;
    }

    attached() {
        this.isAttached = true;
        this._load();
    }

    leaderboardTypeFilter(filterValue, row) {
        if (!filterValue) return true;
        if (filterValue === 'mid-contest') return row.applyMidContestStandards;
        else if (filterValue === 'full-contest') return !row.applyMidContestStandards;
        return true;
    }

    codeChanged() {
        if (!this.isAttached) return;
        this._load();
    }

    async _load() {
        try {
            if (!this.code) return;
            this.loading = true;
            this.standings = [];
            const data = await this._contests.standings(this.code);
            if (!data) return;
            this.contest = data.contest;
            this.standings = data.standings;
            this.latestPolicyDate = data.latestPolicyDate;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    requirementsCssClass(record) {
        if (!this.contest.hasRequirements || !record || !record.isInDownline) return '';
        if (record.meetsRequirements) return 'lpfn-success';
        return 'lpfn-error';
    }

    async refresh() {
        this.refreshing = true;
        await this._load();
        window.setTimeout(() => this.refreshing = false, 2000);
    }

    async showDetails(record) {
        if (!record.isAuthenticatedRecord && !this.security.isAdmin) return;
        try {
            this.loadingDetails = true;
            this.detailsFor = record.member;
            this.memberDetails = undefined;
            const data = await this._contests.standings(this.code, record.member.id);
            this.memberDetails = data.memberDetails;
            this.showDrawer = moment().format();
        } catch (err) {
            console.log(err);
        } finally {
            this.loadingDetails = false;
        }
    }

    drawerClosed() {
        this.detailsFor = undefined;
        this.memberDetails = undefined;
    }
}
