import { bindable,  bindingMode } from 'aurelia-framework';
import { I18n } from 'common/i18n';
import Coloris from "@melloware/coloris";
import { c } from 'common/common';

export class ColorPicker {
    static inject = [I18n];
    _i18n;

    @bindable({ defaultBindingMode: bindingMode.twoWay}) color = undefined;
    @bindable showAlpha = false;
    @bindable swatches;

    pickerEl;
    _picker;

    id;

    constructor(i18n) {
        this._i18n = i18n;
        this.id = c.Helpers.uniqueId();
    }

    attached() {
        this._initializePicker();
    }

    colorChanged() {
        if (!this._initialized || !this.pickerEl) return;
        try {
            document.querySelector(`#${this.id}`).dispatchEvent(new Event('input', { bubbles: true }));
        } catch (err) {
            console.log(err);
        }
    }

    swatchesChanged() {
        if (!this._initialized || !this.pickerEl) return;
        try {
            Coloris({
                swatches: this._transformSwatches(),
            });
        } catch (err) {
            console.log(err);
        }
    }

    _transformSwatches() {
        if (!this.swatches) return [];
        return this.swatches.map(x => x.hex);
    }

    _initializePicker() {
        this._initialized = true;
        Coloris.init();
        Coloris({
            el: `#${this.id}`,
            alpha: this.showAlpha,
            clearButton: {
                show: true,
                label: this._i18n.tr('clear')
            },
            swatches: this._transformSwatches(),
            onChange: (color) => {
                //console.log('coloris', color);
            },
        });
    }
}
