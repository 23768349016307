import { PLATFORM } from 'aurelia-pal';
import { bindable } from 'aurelia-framework';

export class DrilldownObjects {
    @bindable objects;

    clientViewModel = PLATFORM.moduleName('./drilldown-object-client');
    memberViewModel = PLATFORM.moduleName('./drilldown-object-member');
    policyViewModel = PLATFORM.moduleName('./drilldown-object-policy');

    constructor() {
    }

    objectViewModel(type) {
        switch (type) {
            case 'client': return this.clientViewModel;
            case 'member': return this.memberViewModel;
            case 'policy': return this.policyViewModel;
            default: return null;
        }
    }
}
