import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {UiWidgets} from 'services/ui-widgets';
import {I18n} from 'common/i18n';
import {Notifier} from 'common/ui';
import { Content } from 'common/content';
import { c, initializeUiWidgetHtml } from 'common/common';
import tinymce from 'tinymce';

@inject(DialogController, UiWidgets, I18n, Notifier, Content)
export class Editor {
    _content;

    title = '';
    id = null;
    uiWidget = null;
    uiWidgetId = null;
    languageOptions = [];
    locale = '';
    approval = null;
    memberId = null;
    fullEditor = true;
    showTitleEditor = true;
    instructionsKey;
    comment;

    restoreOptions = null;
    restoreOption;

    constructor(dialogController, uiWidgets, i18n, notifier, content) {
        this.dialogController = dialogController;
        this.uiWidgets = uiWidgets;
        this.i18n = i18n;
        this.notifier = notifier;
        this._content = content;

        this.dialogController.settings.centerHorizontalOnly = true;
        this.id = c.Helpers.uniqueId();
        this.languageOptions = this.i18n.supportedLanguages;
        this.locale = this.i18n.currentLocale;
    }

    async activate(model) {
        this.uiWidget = model.uiWidget;
        this.uiWidgetId = null;
        this.widgetType = model.widgetType;
        this.approval = model.approval;
        this.memberId = model.memberId;
        this.fullEditor = model.fullEditor;
        this.title = this.i18n.tr('content-editor-title', { name: model.uiWidget ? model.uiWidget.key : model.editFor } );
        this.showTitleEditor = model.showTitleEditor;
        this.instructionsKey = model.instructionsKey;
        if (this.uiWidget) {
            this.uiWidgetId = this.uiWidget.id;
            // load from the svc so the replaced variables are not displayed
            this.uiWidget = await this.uiWidgets.get(this.uiWidget.key, this.memberId);
        }
    }

    attached() {
        this._initializeTinyMce();
        let activeEditor = tinymce.activeEditor;
        if (activeEditor.initialized) {
            this._setContent(activeEditor);
        } else {
            let me = this;
            activeEditor.on('init', function() {
                me._setContent(this);
            });
        }
    }

    detached() {
        let ae = tinymce.activeEditor;
        if (ae) ae.remove();
    }

    async onLocaleChange() {
        try {
            this.uiWidget = await this.uiWidgets.getForLocale(this.uiWidget.key, this.locale);
            this._setContent(tinymce.activeEditor);
            this.restoreOption = null;
            this.restoreOptions = null;
        } catch (err) {
            this.notifier.errorText('An error occurred and the content for ' + this.locale + ' was not loaded.');
        }
    }

    _setContent(activeEditor) {
        let html = '';
        if (this.uiWidget.html) html = initializeUiWidgetHtml(this.uiWidget.html);
        if (activeEditor.initialized) activeEditor.setContent(html);
    }

    _initializeTinyMce() {
        let me = this;
        tinymce.baseURL = '/tinymce';
        let toolbarRow1 = 'undo redo | styleselect | forecolor backcolor | bold italic | link | image imageslideshow | wufoo iframeembed vimeoembed';
        let toolbarRow2 = 'bootstrap | hr | alignleft aligncenter alignright | bullist numlist outdent indent | code';
        if (!this.fullEditor) {
            toolbarRow1 = 'bold italic | bullist numlist';
            toolbarRow2 = null;
        }
        tinymce.init({
            selector: 'textarea#' + this.id,
            theme: 'modern',
            height: 250,
            
            remove_script_host: false,
            convert_urls: false,

            plugins: ['advlist autolink lists link hr anchor textcolor code image imageslideshow'],
            external_plugins: { 
                'imageslideshow': '/js-lib/tinymce-plugins/imageslideshow/plugin.js',
                'bootstrap': '/js-lib/tinymce-plugins/bootstrap/plugin.min.js',
                'wufoo': '/js-lib/tinymce-plugins/wufoo/plugin.js',
                'iframeembed': '/js-lib/tinymce-plugins/iframeembed/plugin.js',
                'vimeoembed': '/js-lib/tinymce-plugins/vimeoembed/plugin.js'
            },

            toolbar1: toolbarRow1,
            toolbar2: toolbarRow2,
            //toolbar2: 'mc-merge-email mc-merge-fname mc-merge-lname',
            //setup: function(editor) {
            //    editor.addButton('mc-merge-email', { text: 'Insert Email', icon: false, onclick: function() { editor.insertContent('*|EMAIL|*') } }),
            //    editor.addButton('mc-merge-fname', { text: 'Insert First Name', icon: false, onclick: function() { editor.insertContent('*|FNAME|*') } }),
            //    editor.addButton('mc-merge-lname', { text: 'Insert Last Name', icon: false, onclick: function() { editor.insertContent('*|LNAME|*') } })
            //},

            menubar: false,
            toolbar_items_size: 'small',
            extended_valid_elements: 'script[type|src],iframe[src|style|width|height|scrolling|marginwidth|marginheight|frameborder],rssapp-ticker[id],rssapp-imageboard[id]',
            file_browser_callback: function (field_name, url, type, win) {
                me._openFileManager(`#${field_name}`, win);
            },
            slideshow_callback: function (elCssSelector, win, callback, fileType) {
                console.log('slideshow_callback', elCssSelector, win);
                me._openFileManager(elCssSelector, win, callback, fileType);
            },

            valid_elements: '*[*]',

            setup: function (ed) {
                ed.on('init', function(evt) {
                    ed.getBody().setAttribute('spellcheck', true);
                });
            },

            bootstrapConfig: {
                'bootstrapElements': {
                    'template': true
                }
            }
        });
    }

    _openFileManager(elCssSelector, win, callback, fileType) {
        this._content.fileManager(file => {
            const name = file.displayName || file.fileName;
            const inputEl = win.document.querySelectorAll(elCssSelector)[0];
            inputEl.value = file.path;

            if (callback) callback(file.path);
        }, fileType);
    }

    async save(closeEditor) {
        this.uiWidget.html = tinymce.activeEditor.getContent();
        if (this.uiWidget.id) {
            try {
                await this.uiWidgets.update(this.uiWidget.id, this.uiWidget.key, this.locale, this.uiWidget.title, this.uiWidget.html, this.widgetType, this.approval, this.memberId, this.comment, this.uiWidget.fromEmail);
                this.notifier.success('content-widget-saved');
                if (closeEditor) this.dialogController.ok({ uiWidget: this.uiWidget, language: this.locale });
            } catch (err) {
                console.log(error);
                this.notifier.errorText('An error occured saving the content.');
            }
        } else {
            try {
                this.uiWidget = await this.uiWidgets.add(this.uiWidget.key, this.locale, this.uiWidget.title, this.uiWidget.html, this.widgetType, this.approval, this.memberId, this.comment, this.uiWidget.fromEmail);
                this.notifier.success('content-widget-saved');
                if (closeEditor) this.dialogController.ok({ uiWidget: this.uiWidget, language: this.locale });
            } catch (err) {
                this.notifier.errorText('An error occured saving the content.');
            }
        }
    }

    async loadRestoreOptions() {
        this.restoreOptions = await this.uiWidgets.getArchives(this.uiWidget.key, this.locale);
    }

    onRestoreOptionSelected() {
        if (!this.restoreOption) return;
        let activeEditor = tinymce.activeEditor;
        if (activeEditor.initialized) activeEditor.setContent(this.restoreOption.html);
        this.uiWidget.title = this.restoreOption.title;
    }

    cancelRestore() {
        let activeEditor = tinymce.activeEditor;
        if (activeEditor.initialized) activeEditor.setContent(this.uiWidget.html);
        this.restoreOption = null;
    }
}
