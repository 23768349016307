import { bindable } from 'aurelia-framework'
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Commissions } from 'services/commissions';
import { TimePeriodNavigatorBase } from 'resources/base-classes/time-period-navigator-base';
import { WIDGET, COMMISSION } from 'common/constants';

export class CommissionGroup extends TimePeriodNavigatorBase {
    static inject = [EventAggregator, Security, Commissions];
    _commissions;

    @bindable config;

    GROUP_BY = COMMISSION.GroupBy;
    PERIOD = WIDGET.Period;

    constructor(ea, security, commissions) {
        super('commission-group', ea);
        this._security = security;
        this._commissions = commissions;
    }

    attached() {
        this._isAttached = true;
        this._attached();
        if (!this.config) return;
        this._loadData();
    }

    configChanged() {
        if (!this._isAttached) return;
        this._loadData();
    }

    async _loadData() {
        if (!this.config) return;
        if (this._security.isAssistant) return false;
        try {
            this.loading = true;
            if (!this.asOfDate) this.asOfDate = this.config.asOfDate;
            this.initializeTimePeriodNavigation();
            const data = await this._commissions.all(this.config.memberId, this.config.widgetPeriod, this.asOfDate.toISOString(true), this.config.groupBy, this.config.policyId, this.config.clientId, this.config.carrierId);
            this.groupBy = data.groupBy;
            if (data.groups && data.groups.length === 1) data.groups[0].showDetails = true;
            this.groups = data.groups;
            this.displayPaidTo = data.hasMultiplePaidToMembers;
            this.timePeriod = WIDGET.Period.toReportTimePeriod(this.config.widgetPeriod);
            this.currentPeriod = data.period;
            this.setTimePeriodNavigationDisplays();
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
