import { PLATFORM } from 'aurelia-pal';
import { customElement, observable, bindable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import { TemplatingEngine } from 'aurelia-templating';
import { Notifier } from 'common/ui';
import { MemberConfig } from 'services/member-config';
import { Config } from 'services/config';
import { Security } from 'common/security';
import { Dashboards } from 'services/dashboards';
import { DialogService } from 'aurelia-dialog';
import { Menus } from 'services/menus';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import { c } from 'common/common';
import { ROLE } from 'common/constants';
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');

@customElement('dashboard-builder')
export class DashboardBuilder {
    static inject = [Router, EventAggregator, I18n, TemplatingEngine, Notifier, MemberConfig, Config, Security, Dashboards, DialogService, Menus];
    @bindable key;
    @bindable canSelect = true;
    _router;
    _ea;
    _i18n;
    _templatingEngine;
    _notifier;
    _memberConfig;
    _config;
    _dashboards;
    _dialogService;
    _menus;

    @observable dashboardName;

    @observable searchOn;
    editable = false;
    _grid;
    _serializedFull;

    dashboardWidgetsEl;
    gridStackEl;
    widgets = [];
    _widgetSettings = {};

    ADD_PERSONAL_DASHBOARD_KEY = 'add-personal-dashboard';

    _widgetViews = [];

    constructor(router, ea, i18n, templatingEngine, notifier, memberConfig, config, security, dashboards, dialogService, menus) {
        this._router = router;
        this._ea = ea;
        this._i18n = i18n;
        this._templatingEngine = templatingEngine;
        this._notifier = notifier;
        this._memberConfig = memberConfig;
        this._config = config;
        this._security = security;
        this._dashboards = dashboards;
        this._dialogService = dialogService;
        this._menus = menus;
        this._initializeWidgets();
    }

    _initializeWidgets() {
        this.isDashboardAdmin = this._security.isInRole(ROLE.DashboardAdmin);
        this.widgets = this._config.json('dashboardWidgetsJson');
        this.widgets.forEach(w => {
            if (w.showFor && w.showFor.length) {
                let display = false;
                w.showFor.forEach(sf => {
                    switch (sf) {
                        case 'admin': if (this._security.isAdmin) display = true; break;
                        case 'masterAgency': if (this._security.isMasterAgency()) display = true; break;
                        case 'agency': if (this._security.isAgency()) display = true; break;
                        case 'team': if (this._security.isTeam()) display = true; break;
                        case 'agent': display = true; break;
                    }
                });
                w.display = display;
            } else {
                if (w.onlyAdmin && !this._security.isAdmin) w.display = false;
                else if (w.onlyAgency && !this._security.isAgency()) w.display = false;
                else w.display = true;
            }
            // Check for restriction by roles
            if (w.display && w.requireRole) {
                if (!this._security.isInRole(w.requireRole)) w.display = false;
            }
            w.canAccess = w.display;
            if (this.isDashboardAdmin) w.display = true;
        });
    }

    async attached() {
        this.loading = true;
        if (!this._grid) {
            this._initialize();
        } else {
            window.setTimeout(() => this._initialize(), 0);
        }
        this._isAttached = true;
    }

    _initialize() {
        this._initializeGrid();
        if (this.key === this.ADD_PERSONAL_DASHBOARD_KEY) {
            this.addPersonalDashboard();
        } else {
            this.loadGrid();
            if (!this.editable) this.stopEdit();
        }
    }

    detached() {
        try {
            this._ea.publish(c.EventKeys.dashboard.builderDetached);
            if (this.editable) this.stopEdit();
            this._widgetViews.forEach(wv => {
                try {
                    wv.detached();
                } catch (wvErr) {
                    console.log(wvErr);
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    keyChanged(newValue, oldValue) {
        if (!this._isAttached) return;
        this.loading = true;
        if (this.editable) this.stopEdit();
        if (this.key === this.ADD_PERSONAL_DASHBOARD_KEY) {
            this.addPersonalDashboard();
        } else {
            if (this.editingPersonalDashboard) {
                this.closePersonalDashboardEditor(true);
            }
            this.loadGrid();
        }
    }

    @computedFrom('editable', 'editingPersonalDashboard', 'dashboardName')
    get canAddWidgets() {
        if (!this.editable) return false;
        if (!this.editingPersonalDashboard) return true;
        if (this.dashboardName) return true;
        return false;
    }

    async toggleDefault() {
        try {
            await this._dashboards.toggleMemberDefault(this.key);
            this.dashboard.isDefault = !this.dashboard.isDefault;
        } catch (err) {
            console.log(err);
        }
    }

    dashboardNameChanged(newValue, oldValue) {
        if (!this.dashboard || !this.dashboardName) return;
        this.dashboard.name = this.dashboardName;
        this.dashboard.key = `pd-${c.Helpers.keyify(this.dashboardName)}`;
        if (!oldValue) return; // don't need to save the grid when going from nothing to something
        if (this.gridStackEl.childNodes.length) this._saveGrid();
    }

    addPersonalDashboard() {
        this.editingPersonalDashboard = true;
        this._ignoreClearGrid = true;
        this._grid.removeAll(true);
        this.closeToKey = this.ADD_PERSONAL_DASHBOARD_KEY;
        this.dashboard = { key: undefined, savedKey: undefined, canEdit: true, layout: [], savedName: undefined };
        this.startEdit();
        this.focusDashboardName = true;
        this.loading = false;
    }

    deletePersonalDashboard() {
        const model = { key: 'delete-dashboard', okButtonClass: 'btn-danger' };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            try {
                await this._dashboards.delete(this.dashboard.key);
                this.dashboard = undefined;
                this.loading = true;
                this.loadGrid();
            } catch (err) {
                console.log(err);
            }
        });
    }

    editPersonalDashboard() {
        this.editable = true;
        this.editingPersonalDashboard = true;
        this.dashboardName = this.dashboard.name;
        this.startEdit();
    }

    closePersonalDashboardEditor(onlyClear = false) {
        this.editingPersonalDashboard = false;
        this.focusDashboardName = false;
        this.dashboardName = undefined;
        this.stopEdit();
        if (onlyClear) return;
        if (this.closeToKey === this.ADD_PERSONAL_DASHBOARD_KEY) {
            this._router.navigate('#/members', true);
            return;
        }
        if (this.closeToKey) {
            this.dashboard = undefined;
            this.key = this.closeToKey;
            this.closeToKey = undefined;
            this.loading = true;
            this.loadGrid();
        }
    }

    startEdit() {
        if (!this._grid) return;
        this.editable = true;
        this._grid.setStatic(false);
        this._grid.enableMove(true, true);
        this._grid.enableResize(true, true);
    }

    stopEdit() {
        if (!this._grid) return;
        this.editable = false;
        this._grid.setStatic(true);
        this._grid.enableMove(false, true);
        this._grid.enableResize(false, true);
    }

    async setDefault() {
        try {
            await this._dashboards.setDefault(this.dashboard.key);
            this._notifier.success('set-default-dashboard-success');
            this.dashboard.isDifferentThanPublishedDashboard = false;
        } catch (err) {
            console.log(err);
        }
    }

    async reset() {
        try {
            await this._dashboards.reset(this.dashboard.key);
            this._notifier.success('reset-dashboard-success');
            this._clearGrid();
            this.loadGrid();
        } catch (err) {
            console.log(err);
        }
    }

    _clearGrid() {
        this._ignoreClearGrid = true;
        this._grid.removeAll(true);
        this.dashboard = undefined;
    }

    searchOnChanged() {
        this.widgets.forEach(widget => {
            let matchesSearch = true;
            if (this.searchOn) {
                // see if it matches
                const keyMatch = widget.keys
                    .map(x => x.toLowerCase().indexOf(this.searchOn.toLowerCase()) >= 0)
                    .filter(x => x === true);
                matchesSearch = keyMatch.length > 0;
            }
            if (!widget.canAccess) matchesSearch = false;
            widget.display = matchesSearch;
        });
    }

    _initializeGrid() {
        const config = {
            minRow: 1,
            alwaysShowResizeHandle: true,
            acceptWidgets: true,
            removeTimeout: 100,
            staticGrid: false,
            cellHeight: '100px',
        };
        this._grid = GridStack.init(config);
        GridStack.setupDragIn('.lpfn-dashboard-new-widget', { revert: 'invalid', scroll: false, appendTo: 'body', helper: 'clone' });
        this._grid.on('added', (e, items) => {
            try {
                this.focusDashboardName = false;
                if (!this.gridStackEl) return;
                for (let node of this.gridStackEl.childNodes) {
                    let widgetContent = node.querySelector('.grid-stack-item-content');
                    let widgetIsNew = widgetContent.querySelector('.lpfn-dashboard-widget-default-size');
                    if (!widgetIsNew) {
                        continue;
                    }
                    // this is default content, replace with the widget
                    let id = c.Helpers.uniqueId();
                    let widgetId = node.getAttribute('data-widget-id');
                    let settings = undefined;
                    let savedWidget = false;
                    if (!widgetId) {
                        // This is being added from the database, need to add attributes
                        node.classList.add('lpfn-dashboard-new-widget');
                        const loadingWidgetContent = widgetContent.firstChild;
                        widgetId = loadingWidgetContent.getAttribute('data-widget-id');
                        id = loadingWidgetContent.getAttribute('data-id');
                        settings = this._widgetSettings[id];
                        node.setAttribute('data-widget-id', widgetId);
                        savedWidget = true;
                    }
                    widgetContent.classList.add('lpfn-dashboard-widget');
                    widgetContent.classList.remove('lpfn-dashboard-new-widget');
                    const widget = this.widgets.find(x => x.id === widgetId);
                    if (!savedWidget) {
                        settings = widget.defaultSettings ? widget.defaultSettings : undefined;
                        this._widgetSettings[id] = settings;
                    }
                    let settingsValue;
                    if (settings) {
                        settingsValue = JSON.stringify(settings);
                        settingsValue = c.Helpers.replaceAll(settingsValue, '"', '\'');
                    }
                    let configValue;
                    if (widget.config) {
                        configValue = JSON.stringify(widget.config);
                        configValue = c.Helpers.replaceAll(configValue, '"', '\'');
                    }
                    let widgetButtonsHTML = `<a href="#" show.bind="editable" class="btn btn-icon btn-danger btn-sm lpfn-dashboard-widget-button lpfn-dashboard-widget-remove" click.delegate="removeWidget('${id}')" title="Remove widget"><i class="fa-solid fa-trash-can"></i></a>`;
                    if (this._security.isInRole([ROLE.Admin, ROLE.DashboardAdmin])) {
                        widgetButtonsHTML += `<a href="#" show.bind="editable" class="btn btn-icon btn-sm lpfn-dashboard-widget-button lpfn-dashboard-widget-big-button lpfn-success" click.delegate="addWidgetToBigButtons('${id}', '${widgetId}')" title="Add to mobile menu"><i class="fa-solid fa-mobile-retro"></i></a>`;
                    }
                    widgetContent.innerHTML = `<${widgetId} data-id="${id}" is-for-agency.bind="${this.dashboard.isForAgency}" can-edit.bind="${this.dashboard.canEdit}" settings.delegate="widgetSettingsChanged('${id}', '${widgetId}', $event.detail)" settings.bind="${settingsValue}" config.bind="${configValue}" is-published.bind="${this.dashboard.isPublishedDashboard}"></${widgetId}>${widgetButtonsHTML}`;
                    const view = this._templatingEngine.enhance({ element: widgetContent, bindingContext: this });
                    this._widgetViews.push(view);
                }
                if (this.loadingGrid) {
                    // Do not save for the initial load
                    this.loadingGrid = false;
                    return;
                }
                this._saveGrid();
            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false;
            }
        });
        this._grid.on('removed', (e, items) => {
            if (this._ignoreClearGrid) {
                this._ignoreClearGrid = false;
                return;
            }
            this._saveGrid();
        });
        this._grid.on('change', (e, items) => {
            this._saveGrid();
        });
    }

    removeWidget(id) {
        let removeEl;
        for (let node of this.gridStackEl.childNodes) {
            const removeWidget = node.querySelector(`[data-id="${id}"]`);
            if (removeWidget) {
                removeEl = node;
                break;
            }
        }
        if (!removeEl) return;
        this._grid.removeWidget(removeEl);
        this._widgetSettings[id] = undefined;
    }

    addWidgetToBigButtons(id, widgetId) {
        try {
            const widget = this.widgets.find(x => x.id === widgetId);
            const widgetSettings = this._widgetSettings[id];
            const title = widgetSettings.friendlyName ?? this._i18n.tr(widget.key ?? widget.id);
            this._menus.addToMobileMenu(id, 'Widget', widgetId, title, '', `#/members/_w/mobile/${id}`, widget.icon, widgetSettings);
        } catch (err) {
            console.log(err);
        }
    }

    widgetSettingsChanged(id, widgetId, detail) {
        this._widgetSettings[id] = detail.data;
        if (detail.save === true) this._saveGrid();
    }

    async _saveGrid() {
        if (!this.editable) return;
        if (this.editingPersonalDashboard && !this.dashboard.key) {
            this._notifier.error('personal-dashboard-requires-name');
            this.focusDashboardName = true;
            return;
        }
        try {
            const dashboardWidgets = this._grid.save(false);
            const cleanedDashboardWidgets = [];
            for (let node of this.gridStackEl.childNodes) {
                const widgetId = node.getAttribute('data-widget-id');
                const w = Number(node.getAttribute('gs-w'));
                const h = Number(node.getAttribute('gs-h'));
                const x = Number(node.getAttribute('gs-x'));
                const y = Number(node.getAttribute('gs-y'));
                const dashboardWidget = dashboardWidgets.find(dw => dw.x === x && dw.y === y && dw.w === w && dw.h === h);
                if (!dashboardWidget) continue;

                delete dashboardWidget.content;
                const widgetContent = node.querySelector('.grid-stack-item-content');
                const widget = widgetContent.firstChild;
                const id = widget.getAttribute('data-id');
                dashboardWidget.id = id;
                dashboardWidget.widgetId = widgetId;
                dashboardWidget.settings = this._widgetSettings[id];
                cleanedDashboardWidgets.push(dashboardWidget);
            }
            const saveName = this.editingPersonalDashboard ? this.dashboard.name : undefined;
            const saveOriginalKey = this.editingPersonalDashboard && this.dashboard && this.dashboard.savedKey ? this.dashboard.savedKey : undefined;
            const triggerMenuUpdate = this.editingPersonalDashboard && (!this.dashboard.savedKey || (this.dashboard.savedName !== this.dashboard.name));
            await this._dashboards.save(this.dashboard.key, cleanedDashboardWidgets, saveName, saveOriginalKey, triggerMenuUpdate);
            if (this.editingPersonalDashboard) {
                this.dashboard.savedKey = this.dashboard.key;
                this.dashboard.savedName = this.dashboard.name;
            }
            if (triggerMenuUpdate) this.closeToKey = undefined;
            if (this.dashboard.isPublishedDashboard) {
                // This is a Dashboard Admin saving a published dashboard
                this.dashboard.isDifferentThanPublishedDashboard = true;
            }
        } catch (err) {
            console.log(err);
            this._notifier.error('An error occurred saving the dashboard');
        }
    }

    async loadGrid() {
        const dashboard = await this._dashboards.byKey(this.key);
        if (this.dashboard && this.dashboard.key === dashboard.key) {
            this.loading = false;
            return;
        }
        this.dashboard = dashboard;
        if (!this.dashboard) {
            this.loading = false;
            return;
        }
        const cleanedDashboard = [];
        this.dashboard.layout.forEach(dw => {
            const widget = this.widgets.find(x => x.id === dw.widgetId);
            if (!widget) return;
            cleanedDashboard.push(dw);
            if (dw.settings) {
                this._widgetSettings[dw.id] = dw.settings;
            }
            // Need to add the content so the widget added will be added correctly
            dw.content = `<div data-id="${dw.id}" data-widget-id="${widget.id}"><i class="fa ${widget.icon} lpfn-dashboard-widget-icon" aria-hidden="true"></i></div>
                <div class="lpfn-dashboard-widget-name">${this._i18n.tr(`${widget.id}`)}</div>
                <div class="lpfn-dashboard-widget-default-size">${widget.defaultWidth} x ${widget.defaultHeight}</div>`;
        });

        this.loadingGrid = cleanedDashboard.length > 0;
        this._grid.load(cleanedDashboard, true);
        if (cleanedDashboard.length === 0) {
            this.loading = false;
        }
    }

    scrollWidgets(direction) {
        try {
            this.dashboardWidgetsEl.scrollBy({
                top: direction *= 100,
                left: 0,
                behavior: 'smooth'
            });
        } catch (err) {
            console.log(err);
        }
    }
}
