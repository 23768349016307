import { bindable } from 'aurelia-framework';
import { Notifier } from 'common/ui';
import copy from 'copy-to-clipboard';

export class CopyToClipboard {
    static inject = [Notifier];
    @bindable value;
    _notifier;

    constructor(notifier) {
        this._notifier = notifier;
    }

    copyToClipboard(ev) {
        try {
            ev.stopPropagation();
            ev.preventDefault();
            copy(this.value);
            this._notifier.success('copy-to-clipboard-success');
        } catch (err) {
            console.log(err);
        }
    }
}
