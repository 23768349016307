import { Dialer, DIALER_TEAM } from 'services/dialer';
import { Notifier } from 'common/ui';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { c } from 'common/common';

export class AutoDialButton {
    static inject = [Dialer, Notifier, NewInstance.of(ValidationController)];
    _dialer;
    _notifier;

    phoneNumber;

    constructor(dialer, notifier, validationController) {
        this._dialer = dialer;
        this._notifier = notifier;

		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('phoneNumber').required().matches(c.RegEx.phoneNumber)
            .on(this);
    }

    async autoDial() {
        if (this.dialing) return;
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            this.dialing = true;
            const response = await this._dialer.call(undefined, DIALER_TEAM.NoPrompt, this.phoneNumber);
            if (response.succeeded) this._notifier.success('auto-dialer-success');
        } catch (err) {
            console.log(err);
        } finally {
            this.dialing = false;
        }
    }
}