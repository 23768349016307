import { bindable } from 'aurelia-framework'
import { COMMISSION } from 'common/constants';

export class CommissionList {
    _commissions;

    @bindable commissions;
    @bindable groupBy;
    @bindable displayPaidTo;

    GROUP_BY = COMMISSION.GroupBy;

    constructor() {
    }
}
