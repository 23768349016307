import { customElement, bindable } from 'aurelia-framework';
import { Carriers } from 'services/carriers';
import moment from 'moment';

@customElement('commission-grid')
export class CommissionGrid {
    static inject = [Carriers];
    _carriers;

    @bindable carrierId;
    displayAllRates = false;

    constructor(carriers) {
        this._carriers = carriers;
    }

    attached() {
        this._load();
    }

    carrierIdChanged() {
        this._load();
    }

    async _load() {
        if (!this.carrierId) return;
        this.carrierNotesKey = `carrier-commission-grid-${this.carrierId}-notes`;
        try {
            this.loading = true;
            const data = await this._carriers.commissionGrid(this.carrierId);
            this.grids = data.grids;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    commissionRate(grid, level, productGroup) {
        const cell = grid.grid.find(x => x.level === level && x.productGroup === productGroup);
        if (!cell) return undefined;
        return cell.commissionRate;
    }

    commissionRateCellClass(grid) {
        // have 12 to work with
        if (grid.productGroups.length > 4) return 'col-sm-1';
        if (grid.productGroups.length > 3) return 'col-sm-2';
        return 'col-sm-3';

    }

    toggleRates() {
        this.displayAllRates = !this.displayAllRates;
    }
}
