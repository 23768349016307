import { bindable, bindingMode } from 'aurelia-framework';
import { CssAnimator } from 'aurelia-animator-css';

export class ContainerAndDrawer {
    static inject = [Element, CssAnimator];
    _animator;

    @bindable position = 'bottom';
    @bindable({ defaultBindingMode: bindingMode.twoWay }) toggleTrigger;
    @bindable autoHeight = false;
    @bindable rightSide = false;
    showDrawer = false;

    drawerEl;

    constructor(element, animator) {
        this._element = element;
        this._animator = animator;
    }

    toggleTriggerChanged(newValue, oldValue) {
        if (oldValue === null) return; // do nothing on the initial set of the variable by the page
        this.toggleDrawer();
    }

    toggleDrawer() {
        this._animateDrawer(!this.showDrawer);
        // hide drawer just before the animation (300ms) ends
        window.setTimeout(() => this._toggleDrawer(), this.showDrawer ? 200 : 0);
    }

    _toggleDrawer() {
        this.showDrawer = !this.showDrawer;
        if (!this.showDrawer) {
            this._element.dispatchEvent(new CustomEvent('drawerclosed', { bubbles: true, detail: {} }));
        } else {
            this._element.dispatchEvent(new CustomEvent('draweropened', { bubbles: true, detail: {} }));
        }
    }

    _animateDrawer(showDrawer) {
        if (!this.drawerEl) return;
        if (showDrawer) {
            this._animator.addClass(this.drawerEl, 'lpfn-drawer-show');
        } else {
            this._animator.removeClass(this.drawerEl, 'lpfn-drawer-show');
        }
    }
}
