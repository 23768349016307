import { bindable } from 'aurelia-framework';
import { Contests } from 'services/contests';
import { Security } from 'common/security';
import moment from 'moment';

export class ContestProgress {
    static inject = [Contests, Security];
    @bindable code = null;
    @bindable memberId = null;
    _contests;
    security;

    contest;
    standing;
    member;

    constructor(contests, security) {
        this._contests = contests;
        this.security = security;
    }

    attached() {
        this.isAttached = true;
        this._load();
    }

    codeChanged() {
        if (!this.isAttached) return;
        this._load();
    }

    memberIdChanged() {
        if (!this.isAttached) return;
        this._load();
    }

    requirementsCssClass(standing) {
        if (!this.contest.hasRequirements || !standing || !standing.isInDownline) return '';
        if (standing.meetsRequirements) return 'lpfn-success';
        return 'lpfn-error';
    }

    async _load() {
        try {
            if (!this.code) return;
            this.loading = true;
            this.gradientStyle = {};
            this.standing = undefined;
            const memberId = this.memberId || this.security.authenticatedMemberId;
            const data = await this._contests.memberStanding(this.code, memberId);
            if (!data) return;

            this.latestPolicyDate = data.latestPolicyDate;
            this.contest = data.contest;

            this.standing = data.standings.length === 1 ? data.standings[0] : { points: 0 };
            this.member = data.member;
            this.memberDetails = data.memberDetails;
            const progressPercentage = this.standing
                ? (this.standing.points / this.contest.qualifyingPoints) * 100
                : 0;
            const gradientStyle = {
                background: `linear-gradient(0deg, rgba(132,189,61,1) 0%, rgba(132,189,61,1) ${progressPercentage}%, rgba(255,255,255,1) ${progressPercentage + 4}%)`,
            };
            if (this.contest.thermometerSettings2) {
                gradientStyle.top = this.contest.thermometerSettings2.top;
                gradientStyle.bottom = this.contest.thermometerSettings2.bottom;
                gradientStyle.left = this.contest.thermometerSettings2.left;
                gradientStyle.right = this.contest.thermometerSettings2.right;
            }
            this.gradientStyle = gradientStyle;

            if (this.contest.qualifyingLevels && this.contest.thermometerSettings2) {
                this.levelLinesStyle = {
                    top: this.contest.thermometerSettings2.top,
                    bottom: this.contest.thermometerSettings2.bottom,
                    left: this.contest.thermometerSettings2.left,
                    right: this.contest.thermometerSettings2.right
                };
                for (let level of this.contest.qualifyingLevels) {
                    const levelProgressPercentage = (level.qualifyingPoints / this.contest.qualifyingPoints) * 100;
                    level.style = {
                        bottom: `${levelProgressPercentage}%`
                    };
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async refresh() {
        this.refreshing = true;
        await this._load();
        window.setTimeout(() => this.refreshing = false, 2000);
    }

    async showDetails() {
        this.showDrawer = moment().format();
    }

    drawerClosed() {
    }
}
