import { EventAggregator } from 'aurelia-event-aggregator';
import { Contests } from 'services/contests';
import { Security } from 'common/security';
import { TimePeriodNavigatorBase } from 'resources/base-classes/time-period-navigator-base';
import { WIDGET } from 'common/constants';

export class MonthlyConsistency extends TimePeriodNavigatorBase {
    static inject = [EventAggregator, Contests, Security];
    _contests;
    security;

    standings;

    constructor(ea, contests, security) {
        super('monthly-consistency', ea);
        this._contests = contests;
        this.security = security;
    }

    attached() {
        this.isAttached = true;
        this._attached();
        this._loadData();
    }

    async _loadData(fromSync = false) {
        try {
            this.loading = true;
            this.initializeTimePeriodNavigation(fromSync);

            const data = await this._contests.monthlyConsistency(this.asOfDate.toISOString(true));
            this.records = data.records;
            this.timePeriod = WIDGET.Period.toReportTimePeriod(WIDGET.Period.Month);
            this.currentPeriod = data.month;
            this.setTimePeriodNavigationDisplays();
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
