import { customElement, bindable,  bindingMode } from 'aurelia-framework';
import moment from 'moment';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

@customElement('date-picker')
export class DateTimePicker {
    static inject = [Element];

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value = undefined;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) range = undefined;
    @bindable iconOnly = false;
    @bindable type = 'date';
    @bindable allowPast = true;
    @bindable allowFuture = true;
    @bindable disabled = false;
    @bindable showClear = false;
    @bindable noDateKey = '';
    @bindable theme = 'light';
    @bindable valueIfOpened;
    @bindable displayFormat;
    @bindable flatpickrFormat;
    @bindable clearNow;
    @bindable size;

    pickerEl;
    _picker;

    enableTime;
    noCalendar;
    dateFormat;
    altFormat;
    defaultDate;
    momentFormat;
    momentAltFormat;
    _plugins = [];

    noValue = false;

    constructor(element) {
        this._element = element;
    }

    bind() {
        if (this.type === 'date') {
            this.enableTime = false;
            this.noCalendar = false;
            this.dateFormat = 'Y-m-d';
            this.altFormat = this.flatpickrFormat || 'n/j/Y';
            this.momentFormat = 'Y-M-D';
            this.momentAltFormat = this.displayFormat || 'l';
        } else if (this.type === 'range') {
            this.enableTime = false;
            this.noCalendar = false;
            this.dateFormat = 'Y-m-d';
            this.altFormat = this.flatpickrFormat || 'n/j/Y';
            this.momentFormat = 'Y-M-D';
            this.momentAltFormat = this.displayFormat || 'l';
        } else if (this.type === 'time') {
            this.enableTime = true;
            this.noCalendar = true;
            this.dateFormat = 'H:i';
            this.altFormat = this.flatpickrFormat || 'h:i K';
            this.momentFormat = 'HH:mm';
            this.allowPast = true;
            this.momentAltFormat = this.displayFormat || 'h:mm A';
        } else if (this.type === 'month') {
            this.enableTime = false;
            this.noCalendar = false;
            this.dateFormat = 'M Y';
            this.altFormat = 'M Y';
            this.momentFormat = 'Y M';
            this.momentAltFormat = 'MMM YYYY';
            this._plugins.push(new monthSelectPlugin({ shorthand: true, dateFormat: 'M Y', altFormat: 'M Y', theme: this.theme }));
        } else {
            this.enableTime = true;
            this.noCalendar = false;
            this.dateFormat = 'Y-m-d H:i';
            this.altFormat = this.flatpickrFormat || 'D M j, Y h:i K';
            this.momentFormat = 'Y-M-D HH:mm';
            this.momentAltFormat = this.displayFormat || 'ddd MMM D, YYYY h:mm A';
        }
        this._setDates();
    }

    allowPastChanged() {
        if (!this._picker) return;
        this._picker.set('minDate', this.allowPast ? undefined : moment().format(this.momentFormat));
    }

    allowFutureChanged() {
        if (!this._picker) return;
        this._picker.set('maxDate', this.allowFuture ? undefined : moment().format(this.momentFormat));
    }

    _setDates() {
        try {
            if ((this.type !== 'range' && !this.value) || (this.type === 'range' && !this.range)) {
                this.noValue = true;
                return;
            }
            if (this.type === 'time') {
                if (moment.isMoment(this.value)) {
                    this.defaultDate = this.value.format(this.momentFormat);
                    this.disabledValue = this.value.format(this.momentAltFormat);
                } else {
                    this.defaultDate = this.value;
                    this.disabledValue = this.value;
                }
            } else if (this.type === 'range') {
                let defaultRange = undefined;
                if (this.range && this.range.length === 2) {
                    defaultRange = [];
                    defaultRange.push(this.range[0].format(this.momentFormat));
                    defaultRange.push(this.range[1].format(this.momentFormat));
                }
                this.defaultDate = defaultRange;
                this.disabledValue = undefined;
            } else if (this.type === 'month') {
                if (!moment.isMoment(this.value)) this.value = moment(this.value);
                this.defaultDate = this.value ? this.value.toISOString() : undefined;
                this.disabledValue = this.value ? this.value.format(this.momentAltFormat) : undefined;
            } else {
                if (!moment.isMoment(this.value)) this.value = moment(this.value);
                this.defaultDate = this.value ? this.value.format(this.momentFormat) : undefined;
                this.disabledValue = this.value ? this.value.format(this.momentAltFormat) : undefined;
            }
        } catch (err) {
            console.log(err);
        }
    }

    attached() {
        if (this.iconOnly) this._element.classList.add('lpfn-datepicker-icon-only');
        if (this.noValue) return;
        this._createPicker();
    }

    _createPicker() {
        try {
            this.disposePicker(this.defaultDate);
            const config = {
                disableMobile: false,
                altInput: true,
                enableTime: this.enableTime,
                noCalendar: this.noCalendar,
                dateFormat: this.dateFormat,
                altFormat: this.altFormat,
                minDate: this.allowPast ? undefined : moment().format(this.momentFormat),
                maxDate: this.allowFuture ? undefined : moment().format(this.momentFormat),
                defaultDate: this.defaultDate,
                onOpen: () => {
                    this.isClosing = false;
                },
                onClose: (selectedDates, dateStr, instance) => {
                    if (this.type === 'range') {
                        this.range = [moment(selectedDates[0]), moment(selectedDates[1])];
                        this.disabledValue = `${this.range[0].format(this.momentAltFormat)} - ${this.range[1].format(this.momentAltFormat)}`;
                        this._element.dispatchEvent(new CustomEvent('set', { bubbles: true, detail: { range: this.range }}));
                    } else {
                        if (moment(selectedDates[0]).isValid()) {
                            this.value = moment(selectedDates[0]);
                            this.disabledValue = this.value.format(this.momentAltFormat);
                            this._element.dispatchEvent(new CustomEvent('set', { bubbles: true, detail: { date: this.value }}));
                        } else {
                            this.value = undefined;
                            this.disabledValue = undefined;
                            this._element.dispatchEvent(new CustomEvent('set', { bubbles: true, detail: { }}));
                        }
                    }
                    this.isClosing = true;
                    window.setTimeout(() => this.isClosing = false, 250);
                },
            };

            if (this._plugins.length) {
                config.plugins = this._plugins;
            }
    
            if (this.type === 'range') {
                config.mode = 'range';
                if (!config.defaultDate || config.defaultDate.length !== 2) delete config.defaultDate;
            }

            this._picker = flatpickr(this.pickerEl, config);
        } catch (err) {
            console.log(err);
        }
    }

    valueChanged(newValue, oldValue) {
        try {
            if (this.clearing) {
                this.clearing = false;
                return;
            }
            if (this.type === 'time') {

            }
            if (newValue && moment.isMoment(newValue) && !newValue.isValid()) return;
            if (oldValue && newValue && (moment.isMoment(oldValue) && !moment.isMoment(newValue)) || (moment.isMoment(newValue) && !moment.isMoment(oldValue))) {
                if (!moment.isMoment(oldValue)) {
                    const oldMoment = moment(oldValue);
                    if (oldMoment.isSame(newValue)) return;
                } else {
                    const newMoment = moment(newValue);
                    if (newMoment.isSame(oldValue)) return;
                }
            }
            if (this.noValue && this.value) {
                this.noValue = false;
                this._createPicker();
            }
            this._setDates();
            if (!this._picker) return;
            this._picker.setDate(this.defaultDate, false);
        } catch (err) {
            console.log(err);
        }
    }

    disposePicker(defaultDate) {
        if (!this._picker) return;
        this._picker.setDate(defaultDate, false);
        this._picker.destroy();
        this._picker = undefined;
    }

    toggleCalendar() {
        if (this.noValue) {
            this.noValue = false;
            this._createPicker();
            if (this.type !== 'range') {
                this.value = this.valueIfOpened ? moment(this.valueIfOpened) : moment();
            }
        }
        if (!this._picker) return;
        if (this.isClosing) {
            this.isClosing = false;
            return;
        }
        if (this._picker.isOpen) this._picker.close();
        else this._picker.open();
        return false;
    }

    clearNowChanged() {
        this.clearCalendar();
    }

    clearCalendar() {
        this.clearing = true;
        this.noValue = true;
        this.disposePicker();
        this.value = undefined;
        this.range = undefined;
        this.disabledValue = undefined;
        this._element.dispatchEvent(new CustomEvent('set', { bubbles: true, detail: { date: undefined, range: undefined }}));
    }
}
