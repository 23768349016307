import { PLATFORM } from 'aurelia-pal';
import {inject, customElement, bindable} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Editor} from './dialog/editor';
import {UiWidgets} from 'services/ui-widgets';
import {Security} from 'common/security';
import {I18n} from 'common/i18n';
import { c, initializeUiWidgetHtml } from 'common/common';
import environment from '../../../../config/environment.json';
PLATFORM.moduleName('./dialog/editor');

@customElement('content-view')
@inject(DialogService, EventAggregator, UiWidgets, Security, I18n)
export class ContentView {
    id = '';
    @bindable key;
    @bindable navbar;
    @bindable widgetType = '';
    @bindable hideTitle = false;
    @bindable hideContent = false;
    @bindable saveEventKey = '';
    @bindable iconHover = '';
    @bindable onlyEditor = false;
    @bindable onlyEditorButton = '';
    @bindable onlyEditorIcon = '';
    @bindable onlyEditorTextKey = '';
    @bindable titleH3 = false;
    @bindable titlePrepend = '';
    @bindable approval = null;
    @bindable titleCssClass = '';
    @bindable memberId = null;
    @bindable memberEdit = false;
    @bindable memberEditorButton = '';
    @bindable memberEditorTextKey = '';
    @bindable fullEditor = true;
    @bindable showTitleEditor = true;
    @bindable instructionsKey = '';
    hasNavbar = false;
    uiWidget = null;
    canEdit = false;
    @bindable skipReplaceVariables = false;

    _sliderIntervals = {};
    _sliderChecks = {};

    _handlers = [];

    constructor(dialogService, eventAggregator, uiWidgets, security, i18n) {
        this.dialogService = dialogService;
        this.eventAggregator = eventAggregator;
        this.uiWidgets = uiWidgets;
        this.security = security;
        this.i18n = i18n;

        this.id = c.Helpers.uniqueId();
        this.canEdit = this.security.canEditContent();
        this._subscribeToEvents();
    }

    async bind(bindingContext, overrideContext) {
        try {
            if (!this.key) {
                this.uiWidget = null;
                this.widgetType = null;
                this._setTitle();
                return;
            }
            this.uiWidget = await this.uiWidgets.get(this.key, this.memberId);
            if (this._isAttached) this._startInitializingEmbeddedContent();
            if (!this.widgetType) this.widgetType = this.uiWidget ? this.uiWidget.widgetType : null;
            this._setTitle();
            this.hasNavbar = this.navbar ? true : false;
            if (!this.iconHover) this.iconHover = this.key;
        } catch (err) {
            console.log(err);
        }
    }

    async keyChanged(newValue, oldValue) {
        try {
            if (newValue) {
                this.uiWidget = await this.uiWidgets.get(this.key, this.memberId);
                if (this._isAttached) this._startInitializingEmbeddedContent();
                if (!this.widgetType) this.widgetType = this.uiWidget ? this.uiWidget.widgetType : null;
                this._setTitle();
            }
            if (!this.iconHover) this.iconHover = this.key;
        } catch (err) {
            console.log(err);
        }
    }

    async memberIdChanged(newValue, oldValue) {
        if (!this.key) return;
        try {
            this.uiWidget = await this.uiWidgets.get(this.key, this.memberId);
            if (this._isAttached) this._startInitializingEmbeddedContent();
            if (!this.widgetType) this.widgetType = this.uiWidget ? this.uiWidget.widgetType : null;
            this._setTitle();
        } catch (err) {
            console.log(err);
        }
    }

    attached() {
        this._isAttached = true;
        if (this.uiWidget) this._startInitializingEmbeddedContent();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    _startInitializingEmbeddedContent() {
        window.setTimeout(() => { this._initializeEmbeddedContent(); }, 250);
    }

    _subscribeToEvents() {
        this._handlers.push(this.eventAggregator.subscribe(c.EventKeys.reloadUiWidget, async (data) => {
            this.uiWidget = await this.uiWidgets.get(this.key, this.memberId)
            if (!this.widgetType) this.widgetType = this.uiWidget ? this.uiWidget.widgetType : null;
            this._setTitle();
            window.setTimeout(() => { this._initializeEmbeddedContent(); }, 750);
        }));
        this._handlers.push(this.eventAggregator.subscribe(c.EventKeys.loginAuthenticationUpdated, (data) => {
            this.canEdit = this.security.canEditContent();
        }));
        this._handlers.push(this.eventAggregator.subscribe(c.EventKeys.uiWidgetUpdated, async (data) => {
            if (this.key !== data.key) return;
            this.uiWidget = await this.uiWidgets.get(this.key, this.memberId);
            if (!this.widgetType) this.widgetType = this.uiWidget ? this.uiWidget.widgetType : null;
            this._setTitle();
            window.setTimeout(() => { this._initializeEmbeddedContent(); }, 750);
        }));
        this._handlers.push(this.eventAggregator.subscribe(c.EventKeys.openContentEditor, (data) => {
            if (this.key !== data.key) return;
            this.openEditor();
        }));
    }

    _setTitle() {
        this.theTitle = '';
        if (!this.uiWidget) return;
        if (this.titlePrepend === '') {
            this.theTitle = this.uiWidget.title;
        } else {
            this.theTitle = this.titlePrepend + this.uiWidget.title;
        }
    }

    openEditor() {
        var model = {
            uiWidget: JSON.parse(JSON.stringify(this.uiWidget)),
            widgetType: this.widgetType,
            approval: this.approval,
            memberId: this.memberId,
            fullEditor: this.fullEditor,
            editFor: this.iconHover,
            showTitleEditor: this.showTitleEditor,
            instructionsKey: this.instructionsKey
        };

        this.dialogService.open({ viewModel: Editor, model: model, ignoreTransitions: true }).whenClosed(async (response) => {
            if (response.wasCancelled) return;
            this.uiWidget = await this.uiWidgets.get(this.key, this.memberId);
            if (!this.widgetType) this.widgetType = this.uiWidget ? this.uiWidget.widgetType : null;
            this._setTitle();
            if (this.saveEventKey) this.eventAggregator.publish(this.saveEventKey, response.output.uiWidget);
            window.setTimeout(() => { this._initializeEmbeddedContent(); }, 500);
        });
    }

    _initializeEmbeddedContent() {
        this._initializeSlideshows();
        this._initializeIframes();
        this._initializeScripts();
        this._replaceVariables();
    }

    _initializeIframes() {
        $('#' + this.id + ' iframe.add-open-in-new-window').each(function (index, value) {
            var that = $(this);
            var isInitialized = that.attr('data-is-initialized');
            if (isInitialized) return;
            that.attr('data-is-initialized', 'true');
            var src = that.attr('src');
            var html = '<a href="'+ src +'" target="_blank" class="lpfn-pull-right"><i class="far fa-window-restore"></i></a>';
            $(html).insertBefore(that);
        });
    }

    _replaceVariables() {
        if (this.skipReplaceVariables) return;
        if (!this.uiWidget || !this.uiWidget.html) return;
        this.uiWidget.html = initializeUiWidgetHtml(this.uiWidget.html, this.security);
    }

    _initializeSlideshows() {
        if (!this.uiWidget || !this.uiWidget.html) return;
        const me = this;
        const hasSlideshow = this.uiWidget.html.indexOf('imageslideshow-placeholder') >= 0;
        if (!hasSlideshow) return;
        const slideshowEls = $(`#${this.id} .content-view-container-html img.imageslideshow-placeholder`);
        if (slideshowEls.length === 0) {
            window.setTimeout(() => { this._initializeSlideshows(); }, 500);
            return;
        }
        let slideIndex = 1;
        slideshowEls.each(function(index, value) {
            try {
                let that = $(this);
                let isInitialized = that.attr('data-is-initialized');
                if (isInitialized) return;
                that.attr('data-is-initialized', 'true');
                that.hide();
                let id = that.attr('id') || c.Helpers.uniqueId();
                let sliderId = `lpfn-slider-${id}-${slideIndex++}`;
                let images = that.attr('data-images').split('|');
                let captions = that.attr('data-captions').split('|');
                let links = that.attr('data-links').split('|');
                let newWindows = that.attr('data-newwindow').split('|');
                let intervalMs = 4013;
                let dataInterval = that.attr('data-interval');
                if (dataInterval && !isNaN(dataInterval)) {
                    intervalMs = Number(dataInterval);
                }

                let html = `<div class="lpfn-slideshow" id="${sliderId}">`;
                for (let i = 0; i < images.length; i++) {
                    let caption = captions[i];
                    let link = links[i];
                    let newWindow = newWindows[i];
                    let imgSrc = images[i];
                    html += '<div class="lpfn-slideshow-slide">';
                    html += '<div class="lpfn-slideshow-arrow-left"><i class="fas fa-chevron-circle-left"></i></div>';
                    html += '<div class="lpfn-slideshow-arrow-right"><i class="fas fa-chevron-circle-right"></i></div>';
                    if (link) {
                        let target = '';
                        if (newWindow === true || newWindow === 'true') target = ' target="_blank"';
                        const hrefStart = link.indexOf('http') === 0 ? '' : `${environment.api}/`;
                        html += `<a href="${hrefStart}${link}"${target}>`;
                    }
                    const imgStart = imgSrc.indexOf('http') === 0 ? '' : `${environment.static}/`;
                    html += `<img title="${caption}" src="${imgStart}${imgSrc}" alt="${caption}" />`;
                    if (link) {
                        html += '</a>';
                    }
                    html += '</div>';
                }
                html += '</div>';

                $(html).insertAfter(that);
                that.css('border-width', '0px');

                const nextSlide = (slider, forSliderId, forSliderIntervals, fromClick = false) => {
                    if (forSliderIntervals[forSliderId].isPaused && !fromClick) return;
                    if (fromClick) {
                        window.clearInterval(forSliderIntervals[forSliderId].interval);
                    }
                    const first = slider.childNodes[0];
                    const before = slider.querySelector('.lpfn-slideshow-showing');
                    if (before) {
                        before.classList.remove('lpfn-slideshow-showing');
                        const next = before.nextElementSibling;
                        if (next) {
                            next.classList.add('lpfn-slideshow-showing');
                        } else {
                            first.classList.add('lpfn-slideshow-showing');
                        }
                    } else {
                        first.classList.add('lpfn-slideshow-showing');
                    }
                };

                const previousSlide = (slider, forSliderId, forSliderIntervals, fromClick = false) => {
                    if (forSliderIntervals[forSliderId].isPaused && !fromClick) return;
                    if (fromClick) {
                        window.clearInterval(forSliderIntervals[forSliderId].interval);
                    }
                    const last = slider.childNodes[slider.childNodes.length - 1];
                    const before = slider.querySelector('.lpfn-slideshow-showing');
                    if (before) {
                        before.classList.remove('lpfn-slideshow-showing');
                        const previous = before.previousElementSibling;
                        if (previous) {
                            previous.classList.add('lpfn-slideshow-showing');
                        } else {
                            last.classList.add('lpfn-slideshow-showing');
                        }
                    } else {
                        last.classList.add('lpfn-slideshow-showing');
                    }
                };

                $(`#${sliderId} .lpfn-slideshow-arrow-left`).each(function(index, value) {
                    this.addEventListener('click', () => {
                        previousSlide(thisSlider, sliderId, me._sliderIntervals, true);
                        me._sliderIntervals[sliderId].interval = window.setInterval(() => nextSlide(thisSlider, sliderId, me._sliderIntervals), intervalMs);
                    });
                });
                $(`#${sliderId} .lpfn-slideshow-arrow-right`).each(function(index, value) {
                    this.addEventListener('click', () => {
                        nextSlide(thisSlider, sliderId, me._sliderIntervals, true);
                        me._sliderIntervals[sliderId].interval = window.setInterval(() => nextSlide(thisSlider, sliderId, me._sliderIntervals), intervalMs);
                    });
                });

                const thisSlider = document.querySelector(`#${sliderId}`);
                const sliderInterval = window.setInterval(() => nextSlide(thisSlider, sliderId, me._sliderIntervals), intervalMs);
                me._sliderIntervals[sliderId] = { isPaused: false, interval: sliderInterval };
                nextSlide(thisSlider, sliderId, me._sliderIntervals);
                thisSlider.addEventListener('mouseover', () => {
                    me._sliderIntervals[sliderId].isPaused = true;
                });
                thisSlider.addEventListener('mouseout', () => {
                    me._sliderIntervals[sliderId].isPaused = false;
                });

                window.setTimeout(() => { me._checkSlideshow(sliderId); }, 500);
            } catch (slideshowErr) {
                console.log(slideshowErr);
            }
        });
    }

    _checkSlideshow(sliderId) {
        if (!this._sliderChecks[sliderId]) this._sliderChecks[sliderId] = 0;
        this._sliderChecks[sliderId]++;
        const thisSlider = document.querySelector(`#${sliderId}`);
        if (thisSlider) return;
        if (this._sliderChecks[sliderId] > 3) {
            this._sliderChecks[sliderId] = 0;
            return;
        }
        window.setTimeout(() => this._initializeSlideshows(), 250);
    }

    _initializeScripts() {
        const id = this.id;
        $(`#${this.id} script`).each(function(index, value) {
            try {
                const frag = document.createRange().createContextualFragment($(this)[0].outerHTML);
                $(`#${id}`).append(frag);
            } catch (err) {
                console.log(err);
            }
        });
    }
}
