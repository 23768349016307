import { bindable, observable, computedFrom } from 'aurelia-framework';
import { Security } from 'common/security';
import { Notifier } from 'common/ui';
import { I18n } from 'common/i18n';
import Dropzone from 'dropzone';

export class DropZone {
    static inject = [Element, Security, Notifier, I18n];
    _element;
    _security;
    _notifier;
    _i18n;

    _dz;
    dropzoneEl;
    @bindable formAction;
    @bindable extensions;
    @bindable messageKey = 'drop-zone-message-default';
    @bindable successKey;
    @bindable errorKey;
    @bindable imageSrc;
    @bindable({ changeHandler: '_setStyles' }) imageBgColor;
    @bindable({ changeHandler: '_setStyles' }) imageMaxHeight;
    @bindable displayNameField = false;
    @bindable open;

    @observable dropzoneFileName = '';

    constructor(element, security, notifier, i18n) {
        this._element = element;
        this._security = security;
        this._notifier = notifier;
        this._i18n = i18n;
	}

    attached() {
        this._setStyles();
        this._initializeDropzone();
    }

    detached() {
        if (!this._dz) return;
        try {
            this._dz.destroy();
            this._dz = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    dropzoneFileNameChanged() {
        this._setFormAction();
    }

    openChanged() {
        if (!this.open) return;
        if (!this._dz) return;
        this._dz.hiddenFileInput.click();
    }

    @computedFrom('displayNameField', 'dropzoneFileName')
    get disableUploadButton() {
        if (!this.displayNameField) return true;
        return this.dropzoneFileName ? false : true;
    }

    _setStyles() {
        const containerStyle = {};
        const imageStyle = {};
        if (this.imageBgColor) containerStyle['background-color'] = this.imageBgColor;
        if (this.imageMaxHeight) imageStyle['max-height'] = this.imageMaxHeight;
        this.imageContainerStyle = containerStyle;
        this.imageStyle = imageStyle;
    }

    formActionChanged() {
        this._setFormAction();
    }

    _setFormAction() {
        if (!this._dz) return;
        try {
            let formAction = this.formAction;
            if (this.formAction) {
                const connector = formAction.indexOf('?') >= 0 ? '&' : '?';
                if (this.displayNameField && this.dropzoneFileName) formAction += `${connector}displayName=${encodeURIComponent(this.dropzoneFileName)}`;
            }
            this._dz.options.url = formAction;
        } catch (err) {
            console.log(err);
        }
    }

    imageSrcChanged() {

    }

    uploadFileWithName(e) {
        try {
            e.preventDefault();
            e.stopPropagation();
            this._dz.processQueue();
        } catch (err) {
            console.log(err);
        }
    }

    displayNameFieldChanged() {
        if (!this._dz) return;
        this._dz.options.autoProcessQueue = !this.displayNameField;
    }

	_initializeDropzone() {
		const me = this;
		const d = new Date();
		const timezoneOffset = d.getTimezoneOffset();

		this._dz = new Dropzone(this.dropzoneEl, {
			dictDefaultMessage: me._i18n.tr(this.messageKey),
			paramName: 'file',
			maxFilesize: 10, // MB
			headers: { 'Authorization': 'Bearer ' + this._security.token, 'X-LEGACY-TimezoneOffset': timezoneOffset },
			acceptedFiles: this.extensions,
			maxFiles: 1,
            timeout: 360000,
            autoProcessQueue: !this.displayNameField,
			init: function () {
				this.on('addedfile', function (file) {
                    me.processing = true;
				});
				this.on('success', function (file, response) {
					this.removeFile(file);
                    me.processing = false;
                    me.dropzoneFileName = '';
                    me._updateImageSrc();
                    me._element.dispatchEvent(new CustomEvent('uploaded', { bubbles: true, detail: {} }));
                    if (me.successKey) me._notifier.success(me.successKey);
				});
				this.on('error', function (file, error, x) {
                    this.removeAllFiles();
                    if (me.errorKey) me._notifier.error(me.errorKey);
					else me._notifier.errorText('Error.<br/>' + error.message);
                    me.processing = false;
				});
			}
		});
	}

    _updateImageSrc() {
        if (!this.imageSrc) return;
        try {
            const qsIndex = this.imageSrc.indexOf('?');
            let newImageSrc = qsIndex >= 0 ? this.imageSrc.substr(0, qsIndex) : this.imageSrc;
            newImageSrc += `?cb=${new Date().getTime()}`;
            this.imageSrc = newImageSrc;
        } catch (err) {
            console.log(err);
        }
    }
}
